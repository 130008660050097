<template>
	<div class="single-view tool-punycode">
		<div class="tool-box">
			<h1>{{ ii('PUNYCODE_CONVERTER') }}</h1>
			<div>
				<div class="tool-input-box">
					<s-text-field
							v-model="app.query.txt"
							ref="tf"
							prepend-icon="i-cursor"
							:placeholder="ii('ENTER_DOMAIN')"
							@change="refreshQuery"
					></s-text-field>
				</div>
				<div>
					<table class="result-tbl">
						<tr v-if="ascii">
							<td>Ascii:</td>
							<td>{{ ascii }}</td>
						</tr>
						<tr v-if="unicode">
							<td>Unicode:</td>
							<td>{{ unicode }}</td>
						</tr>
					</table>
				</div>

				<div class="page-text-content" v-html="markdownToHtml(app.texts.content)"></div>

				<div class="examples-box">
					<h3>{{ ii('INPUT_EXAMPLES') }}:</h3>
					<ul>
						<li v-for="(example, i) in examples">
							<span class="link" @click="onExampleClick(example)">{{ example }}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>



<script>
	import * as punycode from 'punycode';

	export default {
		data() {
			return {
				ascii:null,
				unicode:null,
				examples: [
					'點看',
					'привет.com',
					'xn--b1agamshr.net',
				]
			};
		},
		methods: {
			onExampleClick(example) {
				this.editQueryParams({txt: example});

				this.$refs.tf.focus();
			},
			update(){
				if (!this.app.query.txt)
					return;

				let domain = this.getDomainFromUrl(this.app.query.txt);
				this.app.query.txt = domain;

				this.ascii = punycode.toASCII(domain);
				this.unicode = punycode.toUnicode(domain);
			}
		},
		watch: {
			'app.query.txt'() {
				this.update();
			}
		},
		async mounted() {
			this.update();
			this.$refs.tf.focus();
		}
	}
</script>

<style lang="less">

</style>